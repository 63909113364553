import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Filter from "../components/Filter";
import Stats from "../components/Stats";
import Services from "../components/Services";
import Hero from "../components/Hero";
import FlightDeals from "../components/FlightDeals";

const HomePage = () => {
  return (
    <div className="flex-1 w-full overflow-hidden bg-gray-200">
      
          <Navbar />

      {/* Your Homepage Content */}
      {/* <Hero /> */}

      {/* Your Homepage Content */}
      

      <div className="flex sm:px-6 px-6 justify-center items-center">
        <div className="w-full xl:max-w-[1280px]">
          {/* Your Homepage Content */}
          <Filter />
        </div>
      </div>
      
          {/* Your Homepage Content */}
          <FlightDeals />
        

      <div className="flex sm:px-6 px-0 justify-center items-center">
        <div className="w-full xl:max-w-[1280px]">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
