import { useEffect, useState } from "react";
import { map, xeddo } from "../assets/images";
import { hawaiian } from "../assets/logo";
import { FlightCard, PriceDetails, PriceGraph } from "../container";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import TripLoader from "./TripLoader";
import BookingCard from "./BookingCard";
import { Dialog, DialogContent } from "@mui/material";

const MyBookings = ({ comingTo, headingTo, date, userid }) => {
  const [priceShown, setPriceShow] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const [trips, setTrips] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedTrip, setSelectedTrip] = useState(null);
  const [bookdetailsshow, setBookdetailsShow] = useState(false);

  console.log(userid);

  const formatDate = (date) => {
    if (!date) return "";
    return new Date(date).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  const formattedDate = formatDate(date);

  const getTrips = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://lipabooking.vercel.app/api/v1/user/userbookings/${userid}`
      );
      const data = response.data;
      if (data) {
        setLoading(false);
        setTrips(data);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getTrips();
  }, []);

  const showDetails = (trip) => {
    setSelectedTrip(trip);
    setBookdetailsShow(true);
  };

  const hideBookDetails = () => {
    setBookdetailsShow(false);
    setSelectedTrip(null);
  };

  return (
    <>
      <Dialog open={bookdetailsshow} onClose={hideBookDetails} className="">
        <DialogContent className="bg-white h-80 w-[600px] rounded-2xl justify-center items-center">
          {selectedTrip && (
            <div className="flex flex-col items-center space-y-2">
              <p className="text-xl">From: {selectedTrip.from}</p>
              <p className="text-xl">To: {selectedTrip.to}</p>
              <p className="text-xl">Leaves At: {selectedTrip.leavesAt}</p>
              <p className="text-xl">Vehicle Name: {selectedTrip.vehiclename}</p>
              <p className="text-xl">Vehicle Reg: {selectedTrip.vehiclereg}</p>
              <div className="w-full justify-center items-center">
                <button onClick={hideBookDetails} className="bg-red-500 p-2 rounded-md text-white text-xl">Close</button>
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
      <div className="flex lg:flex-row flex-col items-start justify-between gap-16 ">
        <div className="w-full lg:w-[872px] h-full flex flex-col gap-5">
          <div className="flex items-start justify-start">
            <h1 className="text-[#6E7491] text-lg leading-6 font-semibold">
              Choose a <span className="text-[#605DEC]">departing </span>/{" "}
              <span className="text-[#605DEC]">returning </span>Trip
            </h1>
          </div>
          <div className="flex items-center justify-between w-full">
            <div>
              <p className="text-black font-semibold text-2xl tracking-wider">
                {comingTo}
              </p>
            </div>
            <div>
              <img
                src="../../images/forward.png"
                className="w-8 h-8 object-fit"
                alt=""
              />
            </div>
            <div>
              <p className="font-semibold text-black text-2xl tracking-wider">
                {headingTo}
              </p>
            </div>
          </div>
          <div>
            {loading ? (
              <div>
                <TripLoader />
              </div>
            ) : (
              <div>
                {trips.map((trip) => {
                  return (
                    <div className="w-full flex flex-col items-start justify-start border-[1px] border-[#E9E8FC] rounded-xl">
                      <div
                        className="w-full cursor-pointer border-b-[1px] border-[#E9E8FC] hover:bg-[#F6F6FE] transition-all duration-300 focus:bg-[#F6F6FE]"
                      >
                        <BookingCard
                          img={hawaiian}
                          from={trip.from}
                          name={headingTo}
                          time={trip.leavesAt}
                          to={trip.to}
                          leaves={trip.leavesAt}
                          arrives={trip.arrivalTime}
                          hnl="2h 45m in HNL"
                          vehiclename={trip.vehiclename}
                          vehiclereg={trip.vehiclereg}
                          trip="round trip"
                          showDetails={() => showDetails(trip)}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>

          <div className="w-full lg:mt-12">
            <img src={xeddo} alt="map" className="w-full h-full object-cover" />
          </div>
        </div>

        {priceShown && <PriceGraph />}

        {!priceShown && (
          <div className="mt-10 flex flex-col gap-10 justify-end items-start lg:items-end">
            <PriceDetails />
            <Link to="/passenger-info" className="mt-5">
              <button className="text-[#605DEC] border-2 border-[#605DEC] py-2 px-3 rounded hover:bg-[#605DEC] hover:text-white transition-all duration-200">
                Save & Close
              </button>
            </Link>
          </div>
        )}
      </div>
    </>
  );
};

export default MyBookings;
