import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { comingfrom, headingto } from "../constants";

const Filter = () => {
  const [filters, setFilters] = useState({
    search: "",
    comingTo: "",
    headingTo: "",
    date: "",
  });

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/explore", { state: filters });
  };

  const handleReset = () => {
    setFilters({
      search: "",
      comingTo: "",
      headingTo: "",
      date: "",
    });
  };

  return (
    <div className="w-full sm:px-16 sm:py-16 py-6">
      <div className="flex flex-col">
        <div className="rounded-xl border border-gray-300 p-6 shadow-lg">
          <form onSubmit={handleSubmit}>
            

            <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
              <div className="flex flex-col">
                <label
                  htmlFor="comingTo"
                  className="text-sm font-medium text-stone-600"
                >
                  From:
                </label>
                <select
                  id="comingTo"
                  name="comingTo"
                  value={filters.comingTo}
                  onChange={handleInputChange}
                  className="mt-2 block w-full rounded-md border border-gray-100 bg-gray-100 px-2 py-4 shadow-sm outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                >
                  <option value="">Select Destination</option>
                  {comingfrom.map((from) => (
                    <option key={from.id} value={from.name}>
                      {from.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="flex flex-col">
                <label
                  htmlFor="headingTo"
                  className="text-sm font-medium text-stone-600"
                >
                  Heading To:
                </label>
                <select
                  id="headingTo"
                  name="headingTo"
                  value={filters.headingTo}
                  onChange={handleInputChange}
                  className="mt-2 block w-full rounded-md border border-gray-100 bg-gray-100 px-2 py-4 shadow-sm outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                >
                  <option value="">Select Destination</option>
                  {headingto.map((heading) => (
                    <option key={heading.id} value={heading.name}>
                      {heading.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="flex flex-col">
                <label htmlFor="date" className="text-sm font-medium text-stone-600">
                  Date of Entry
                </label>
                <input
                  type="date"
                  id="date"
                  name="date"
                  value={filters.date}
                  onChange={handleInputChange}
                  className="mt-2 block w-full cursor-pointer rounded-md border border-gray-100 bg-gray-100 px-2 py-4 shadow-sm outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                />
              </div>
            </div>

            <div className="mt-6 grid w-full grid-cols-2 justify-end space-x-4 md:flex">
              <button
                type="button"
                onClick={handleReset}
                className="rounded-lg bg-gray-200 px-8 py-2 font-medium text-gray-700 outline-none hover:opacity-80 focus:ring"
              >
                Reset
              </button>
              <button
                type="submit"
                className="rounded-lg bg-blue-600 px-8 py-2 font-medium text-white outline-none hover:opacity-80 focus:ring"
              >
                Search
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Filter;
