import React, { useEffect, useState } from "react";
import { xeddo } from "../assets/images";
import axios from "axios";
import { toast } from "react-toastify";
import UpdateLoader from "./UpdateLoader";

const Profile = () => {
  const userEmail = localStorage.getItem("email");
  const [userdata, setUserdata] = useState({});
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getUserdata();
  }, []);

  // const getUserdata = () => {
  //   setLoading(true);
  //   fetch(`https://e2d1-197-232-60-144.ngrok-free.app/api/v1/user/user/${userEmail}`)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       const user = data.find((item) => item.email === userEmail);
  //       if (user) {
  //         setUserdata(user);
  //         setUsername(user.username);
  //         setEmail(user.email);
  //         setAddress(user.address);
  //         setPhone(user.phoneNumber);
  //         setLoading(false);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const getUserdata = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://lipabooking.vercel.app/api/v1/user/user/${userEmail}`
      );

      const user = response.data[0];
      if (user) {
        setUserdata(response.data[0]);
        setUsername(user.username);
        setEmail(user.email);
        setAddress(user.address);
        setPhone(user.phone.toString());
        setLoading(false);
      } else {
        alert("error getting user details");
      }
    } catch (error) {
      console.log(error);
      alert("Failed to fetch user data");
    }
  };

  const updateUser = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const response = await axios.put(
        `https://lipabooking.vercel.app/api/v1/user/updateuser/${userEmail}`,
        {
          username: username,
          email: email,
          phone: phone,
          address: address,
        }
      );
      setUserdata(response.data);
      toast.success("User updated successfully");
      setLoading(false);

      // Check if email was updated, and log out if true
      if (response.data.email !== userEmail) {
        toast.warning("Logging you out now!!");
        // Navigate to the logout screen or perform logout-related action
        handleLogout(); // Replace 'LogoutScreen' with your actual logout screen
      }
    } catch (error) {
      console.log(error);
      alert("Failed");
      toast.success("Error occurred while updating");
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    localStorage.removeItem("Authenticated");
    window.location.reload();
  };
  return (
    <div>
      {loading ? (
        <div className="h-screen w-full justify-center items-center">
          <UpdateLoader />
        </div>
      ) : (
        <div>
          <div class="flex justify-center mt-20 px-8">
            <form class="max-w-2xl" onSubmit={updateUser}>
              <div class="flex flex-wrap border shadow rounded-lg p-3 dark:bg-gray-600">
                <div className="flex justify-center items-center w-full">
                  <img src={xeddo} className="w-full rounded-md" alt="" />
                </div>
                <h2 class="text-xl font-bold text-black pb-2">
                  Account settings:
                </h2>

                <div class="flex flex-col gap-2 w-full border-gray-400">
                  <div>
                    <label class="text-gray-600 dark:text-gray-400">
                      Username
                    </label>
                    <input
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      class="w-full py-3 border border-slate-400 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
                      type="text"
                    />
                  </div>

                  <div>
                    <label class="text-gray-600 dark:text-gray-400">
                      Email
                    </label>
                    <input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      class="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
                      type="text"
                    />
                  </div>
                  <div>
                    <label class="text-gray-600 dark:text-gray-400">
                      Address
                    </label>
                    <input
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      class="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
                      type="text"
                    />
                  </div>
                  <div>
                    <label class="text-gray-600 dark:text-gray-400">
                      Phone
                    </label>
                    <input
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      class="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
                      type="text"
                    />
                  </div>

                  <div class="flex justify-between">
                    <button
                      class="py-1.5 px-3 m-1 text-center bg-red-700 border rounded-md text-white  hover:bg-violet-500 hover:text-gray-100 dark:text-gray-200 dark:bg-violet-700"
                      onClick={handleLogout}
                    >
                      Logout
                    </button>
                    <button
                      class="py-1.5 px-3 m-1 text-center bg-violet-500 border rounded-md text-white  hover:bg-violet-500 hover:text-gray-100 dark:text-gray-200 dark:bg-violet-700"
                      type="submit"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile;
