/* eslint-disable react/prop-types */

import { Dialog, DialogContent } from "@mui/material";
import { useState } from "react";

const BookingCard = ({
  img,
  from,
  name,
  time,
  to,
  trip,
  price,
  leaves,
  arrives,
  hnl,
  vehiclename,
  vehiclereg,
  showDetails
}) => {

    
  return (
    <>
    
      <div className="w-full flex flex-row items-start justify-between gap-7 p-4 ">
        <div className="flex items-start gap-2">
          <img
            src={img}
            alt="hawaiian"
            className="w-6 h-6 sm:w-9 sm:h-9 object-contain"
          />
          <div className="flex flex-col items-start justify-start">
            <h2 className="text-[#27273F] font-bold text-lg  md:text-base">
              {from}
            </h2>
            <p className="text-[#7C8DB0] font-normal text-xs   md:text-base">
              {name}
            </p>
          </div>
        </div>
        <div className="flex flex-col items-center sm:items-end justify-start">
          <p className="text-[#27273F] font-normal text-xs   md:text-base">
            Leaves At
          </p>
          <p className="text-[#7C8DB0] font-normal text-xs  md:text-base">
            {leaves}
          </p>
        </div>
        <div className="flex flex-col  items-center sm:items-end justify-start">
          <p className="text-[#27273F] font-bold text-lg   md:text-base">
            {to}
          </p>
        </div>
        <div className="flex flex-col items-center sm:items-end justify-start">
          <p className="text-[#27273F] font-normal text-xs  md:text-base">
            {vehiclename}
          </p>
          <p className="text-[#7C8DB0] font-normal text-xs   md:text-base">
            {vehiclereg}
          </p>
          <p onClick={showDetails} className="p-2 bg-blue-500 flex rounded-md justify-center items-center h-8">
            <p className="text-white">View</p>
          </p>
        </div>
      </div>
    </>
  );
};

export default BookingCard;
