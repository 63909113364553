export const navLinks = [
    {
      id: "home",
      name: "Home",
      path: "/",
    },
    {
      id: "about",
      name: "About",
      path: "/about",
    },
    {
      id: "services",
      name: "Services",
      path: "/services",
    },
    
  ];


  export const headingto = [
    {
      id:1,
      name:'Nairobi',
    },
    {
      id:2,
      name:'Nakuru',
    },
    {
      id:3,
      name:'Mombasa',
    },
    {
      id:4,
      name:'Kisii',
    },
    {
      id:5,
      name:'Kisumu',
    },
    {
      id:6,
      name:'Eldoret',
    },
  ]
  export const comingfrom = [
    {
      id:1,
      name:'Nairobi',
    },
    {
      id:2,
      name:'Nakuru',
    },
    {
      id:3,
      name:'Mombasa',
    },
    {
      id:4,
      name:'Kisii',
    },
    {
      id:5,
      name:'Kisumu',
    },
    {
      id:6,
      name:'Eldoret',
    }
  ]